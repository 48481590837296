<template>
  <div>
    <v-simple-table
      class="elevation-2 rounded"
      :class="draggable ? 'row-scroll' : 'row-pointer'"
    >
      <template v-slot:default>
        <thead>
          <tr class="primary">
            <th
              v-for="(header, indexHeader) in headers"
              :key="indexHeader"
              class="white--text text-no-wrap"
              :class="header.class"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tr v-if="isLoading" class="pa-0 ma-0">
          <td :colspan="headers.length" class="pa-0 ma-0">
            <v-progress-linear indeterminate></v-progress-linear>
          </td>
        </tr>
        <Draggable
          :list="items"
          tag="tbody"
          name="flip-list"
          animation="200"
          v-bind="dragOptions"
          :disabled="!draggable"
          :sort="true"
        >
          <tr
            v-for="(item, index) in items"
            :key="index"
            :class="
              item.sum_price == item.budget_allocated && item.sum_price
                ? 'full-allocate'
                : item.budget_allocated
                ? 'partial-allocate'
                : ''
            "
            @click="!draggable && openEditMaintenanceMethodDialog(item)"
          >
            <td class="text-center">{{ index + 1 }}</td>
            <td class="text-center">{{ item.road_code }}</td>
            <td class="text-center" style="min-width: 126px !important">
              {{ `${getKm(item.km_start || 0)} - ${getKm(item.km_end || 0)}` }}
            </td>
            <td style="min-width: 120px !important">
              {{
                `${item.bridge_name} ${
                  item.direction_name ? `(${item.direction_name})` : ''
                }`
              }}
            </td>
            <td style="min-width: 130px !important">
              {{ item.name || '(ไม่ระบุชื่อ)' }}
            </td>
            <td class="text-center">
              {{ parseFloat(item.bhi || 0).toLocaleString() }}
            </td>
            <td class="text-center">
              {{ parseFloat(item.nbes_29_value || 0).toLocaleString() }}
            </td>
            <!-- <td class="text-center">
              {{ parseFloat(item.nbes_19_value || 0).toLocaleString() }}
            </td> -->
            <td class="text-center">
              {{ parseFloat(item.nbes_28A_value || 0).toLocaleString() }}
            </td>
            <td class="text-center">
              {{ parseFloat(item.nbes_27_value || 0).toLocaleString() }}
            </td>
            <td class="text-end" style="min-width: 100px !important">
              {{ parseFloat(item.sum_price || 0).toLocaleString() }}
            </td>
            <td class="text-end" style="min-width: 100px !important">
              {{
                item.budget_allocated
                  ? parseFloat(item.budget_allocated || 0).toLocaleString()
                  : '-'
              }}
            </td>
          </tr>
        </Draggable>
        <tfoot v-if="$store.getters.edit_budget">
          <tr>
            <td :colspan="headers.length">
              <v-switch
                v-model="draggable"
                hide-details
                label="จัดลำดับ"
                class="pa-3 mt-0 d-flex"
              ></v-switch>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <EditMaintenanceMethodDialog
      v-model="editMaintenanceMethodDialog"
      :repairId="repairId"
      readonly
      @close="editMaintenanceMethodDialog = false"
    />
  </div>
</template>

<script>
import utils from '@/assets/js/utils'
import EditMaintenanceMethodDialog from '../repair/dialog/EditRepairDialog.vue'
import Draggable from 'vuedraggable'
export default {
  props: {
    items: Array,
    isLoading: Boolean
  },
  components: {
    Draggable,
    EditMaintenanceMethodDialog
  },
  computed: {
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false
      }
    }
  },
  data: () => ({
    getKm: utils.getKm,
    headers: [
      {
        text: 'ลำดับ',
        align: 'center',
        sortable: false,
        value: 'sequence',
        class: 'text-center rounded-tl'
      },
      {
        text: 'รหัสสายทาง',
        align: 'center',
        sortable: false,
        value: 'road_code',
        class: 'text-center'
      },
      {
        text: 'กม.',
        align: 'center',
        sortable: false,
        value: 'km',
        class: 'text-center'
      },
      {
        text: 'ชื่อสะพาน',
        sortable: false,
        value: 'bridge',
        class: '',
        width: '50%'
      },
      {
        text: 'ชื่อแผนซ่อมบำรุง',
        sortable: false,
        value: 'name',
        class: ''
      },
      {
        text: 'BHI',
        sortable: false,
        align: 'center',
        value: 'bhi',
        class: 'text-center'
      },
      {
        text: 'ADT',
        align: 'center',
        sortable: false,
        value: 'nbes_29_value',
        class: 'primary white--text text-center'
      },
      // {
      //   text: 'Detour',
      //   align: 'center',
      //   sortable: false,
      //   value: 'nbes_19_value',
      //   class: 'primary white--text text-center'
      // },
      {
        text: 'จำนวนเลน',
        align: 'center',
        sortable: false,
        value: 'nbes_28A_value',
        class: 'primary white--text text-center'
      },
      {
        text: 'อายุ (ปี)',
        align: 'center',
        sortable: false,
        value: 'nbes_27_value',
        class: 'primary white--text text-center'
      },
      {
        text: 'ราคารวม (บาท)',
        sortable: false,
        align: 'right',
        value: 'sum_price',
        class: 'text-end'
      },
      {
        text: 'ได้รับการจัดสรร (บาท)',
        sortable: false,
        align: 'right',
        value: 'budget_allocated',
        class: 'text-end rounded-tr'
      }
    ],
    editMaintenanceMethodDialog: false,
    repairId: null,
    draggable: false
  }),
  methods: {
    openEditMaintenanceMethodDialog (repair) {
      this.repairId = parseInt(repair.id)
      this.editMaintenanceMethodDialog = true
    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.row-scroll >>> tbody tr :hover {
  cursor: all-scroll;
}
.ghost {
  opacity: 0.5;
  background: #feffba;
}
.full-allocate {
  background: #b0ff8c;
}
.partial-allocate {
  background: #feffba;
}
</style>
