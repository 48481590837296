<template>
  <div>
    <div class="pa-4">
      <div class="d-flex pt-4 pb-3 align-center">
        <h2 class="primary--text font-weight-regular">จัดสรรงบประมาณ</h2>
        <v-btn
          color="primary"
          class="ml-auto"
          @click="exportBudgetDialog = !exportBudgetDialog"
        >
          <v-icon left>mdi-export-variant</v-icon>
          ส่งออก
        </v-btn>
      </div>
      <v-form
        ref="form"
        lazy-validation
        class="pt-0"
        enctype="multipart/form-data"
      >
        <v-row class="mt-2">
          <v-col cols="12" sm="4" md="3" class="pr-0">
            <v-select
              v-model="editForm.budget_year"
              dense
              outlined
              hide-details="auto"
              :items="budgetYearList"
              background-color="white"
              placeholder="ปีงบประมาณ"
              :rules="[rules.required]"
            >
              <template v-slot:selection="{ item }">
                ปีงบฯ {{ item }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="8" md="9" class="d-flex">
            <v-text-field
              v-model="name"
              name="search"
              id="id"
              solo
              dense
              hide-details="auto"
              placeholder="ชื่อสะพาน หรือ แผนซ่อมบำรุง"
              prepend-inner-icon="mdi-magnify"
              @keydown.enter="getBridgeRepairAllocateBudgetList"
            >
            </v-text-field>
            <v-btn
              class="primary elevation-2 white--text rounded ml-2 align-self-"
              icon
              tile
              @click="filterBudgetRepairDialog = true"
            >
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="2" :class="$vuetify.breakpoint.xs ? 'pb-0' : ''">
            งบประมาณ (บาท)
          </v-col>
          <v-col cols="12" sm="3" :class="$vuetify.breakpoint.xs ? 'pt-0' : ''">
            <v-text-field
              v-model="budgetAllocate"
              class="right-input"
              type="number"
              dense
              outlined
              hide-details
              background-color="white"
              placeholder="0"
              min="0"
            ></v-text-field>
          </v-col>
          <v-col cols="auto" class="d-flex justify-space-between">
            <div>
              <v-btn class="blue white--text" @click="calculateBudget"
                >จำกัดงบประมาณ</v-btn
              >
              (คงเหลือ
              {{ parseFloat(budgetBalance || 0).toLocaleString() }} บาท)
            </div>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn
              v-if="$store.getters.edit_budget"
              class="green white--text"
              style="width: 140px"
              @click="openConfirmDialog"
              >บันทึก</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
      <div class="mt-4">
        <DraggableTable
          :isLoading="isLoading"
          :items="items"
          :budgetAllocate="budgetAllocate"
        />
      </div>
      <div class="mt-4">
        <v-row justify="end">
          <v-col cols="6" sm="3">
            <div>ราคารวมทั้งหมด (บาท)</div>
          </v-col>
          <v-col cols="4" sm="2">
            {{ sumPrice('sum_price') }}
          </v-col>
          <v-col cols="2" sm="auto" class="text-right">บาท</v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="6" sm="3" class="pt-0">
            <div>ได้รับการจัดสรรทั้งหมด (บาท)</div>
          </v-col>
          <v-col cols="4" sm="2" class="pt-0">
            {{ sumPrice('budget_allocated') }}
          </v-col>
          <v-col cols="2" sm="auto" class="pt-0 text-right">บาท</v-col>
        </v-row>
      </div>
    </div>
    <EditMaintenanceMethodDialog
      v-model="editMaintenanceMethodDialog"
      :repairId="repairId"
      readonly
      @confirm="confirmEdit"
      @close="editMaintenanceMethodDialog = false"
    />
    <FilterBudgetRepairDialog
      v-model="filterBudgetRepairDialog"
      @filter="filterBudgetRepair"
      @close="filterBudgetRepairDialog = false"
    />
    <ConfirmDialog
      v-model="isConfirmDialog"
      :dialog-title="'ยืนยันการบันทึกข้อมูล ?'"
      @confirm="confirmEdit()"
      @cancel="closeConfirmDialog()"
    />
    <ExportBudgetDialog
      v-model="exportBudgetDialog"
      @close="exportBudgetDialog = false"
    />
  </div>
</template>

<script>
import utils from '@/assets/js/utils'
import EditMaintenanceMethodDialog from '@/components/repair/dialog/EditRepairDialog.vue'
import FilterBudgetRepairDialog from '@/components/budget/dialog/FilterBudgetRepairDialog.vue'
import DraggableTable from '@/components/budget/DraggableTable.vue'
import ConfirmDialog from '@/components/dialog/confirmDialog.vue'
import ExportBudgetDialog from '@/components/budget/dialog/ExportBudgetDialog.vue'
export default {
  components: {
    EditMaintenanceMethodDialog,
    FilterBudgetRepairDialog,
    DraggableTable,
    ConfirmDialog,
    ExportBudgetDialog
  },
  data: () => ({
    getKm: utils.getKm,
    exportBudgetDialog: false,
    items: [],
    editMaintenanceMethodDialog: false,
    filterBudgetRepairDialog: false,
    isLoading: true,
    repairId: null,
    drag: true,

    isConfirmDialog: false,
    editForm: {},
    rules: {
      required: (value) => !!value || 'กรุณากรอกข้อมูล'
    },
    budgetAllocate: 0,
    budgetBalance: 0,
    name: null,
    filter: {},
    budgetYearList: []
  }),
  computed: {
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  async mounted () {
    await this.getBudgetYearList()
    await this.getBridgeRepairAllocateBudgetList()
  },
  methods: {
    async getBudgetYearList () {
      this.budgetYearList = await this.$store.dispatch('getBudgetYearList')
      this.editForm.budget_year =
        this.budgetYearList[this.budgetYearList.length - 1]
    },
    async getBridgeRepairAllocateBudgetList () {
      this.isLoading = true
      const filter = { ...this.filter }
      filter.name = this.name
      const items = await this.$store.dispatch(
        'budget/getBridgeRepairAllocateBudgetList',
        { filter, budgetYear: this.editForm.budget_year }
      )
      this.items = items
      this.isLoading = false
    },
    openConfirmDialog () {
      if (this.$refs.form.validate()) {
        this.isConfirmDialog = true
      } else {
        const invalidField = this.$refs.form.inputs.find((e) => !e.valid)
        if (invalidField) {
          invalidField.$el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      }
    },

    async confirmEdit () {
      const sortedId = this.items.map((item) => item.id)
      const payload = {
        budget_year: this.editForm.budget_year,
        sorted_ids: sortedId
      }
      const result = await this.$store.dispatch(
        'budget/setSequenceBridgeRepairAllocateBudgetList',
        payload
      )
      if (result) this.getBridgeRepairAllocateBudgetList()
      this.isConfirmDialog = false
    },
    closeConfirmDialog () {
      this.isConfirmDialog = false
    },
    calculateBudget () {
      let budgetAllocate = parseFloat(this.budgetAllocate)
      this.items.forEach((item) => {
        const sumPrice = parseFloat(item.sum_price || 0)
        this.$set(
          item,
          'budget_allocated',
          budgetAllocate - sumPrice > 0
            ? sumPrice
            : budgetAllocate > 0
              ? budgetAllocate
              : 0
        )
        budgetAllocate -= sumPrice
      })
      this.budgetBalance = budgetAllocate >= 0 ? budgetAllocate : 0
    },
    sumPrice (prop) {
      return this.items
        .reduce(function (a, b) {
          return a + parseFloat(b[prop] || 0)
        }, 0)
        .toLocaleString()
    },
    filterBudgetRepair (filter) {
      this.filter = filter
      this.getBridgeRepairAllocateBudgetList()
      this.filterBudgetRepairDialog = false
    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.ghost {
  opacity: 0.5;
  background: #feffba;
}
.right-input >>> input {
  text-align: right;
}
</style>
